<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <!-- <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          /> -->
          <template v-if="$permissionAbility(DIVISION_CREATE, permissions)">
            <b-button
              class="flex-shrink-0 btn-md mr-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="restoreSelected"
              :disabled="!isRowChecked"
            >
              Restore Selected
            </b-button>
          </template>
          <template v-if="$permissionAbility(DIVISION_CREATE, permissions)">
            <b-button
              class="flex-shrink-0 btn-md"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              v-on:click="deleteSelected"
              :disabled="!isRowChecked"
            >
              Delete Selected
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        :line-numbers="true"
        mode="remote"
        style-class="vgt-table table-custom-style bordered condensed"
        @on-selected-rows-change="selectionChanged"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
        :select-options="{ enabled: true }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- format_members -->
          <template v-if="props?.column?.field === 'format_members'">
            <div class="table-cell-margin">
              <b-badge pill variant="light-primary">
                Member Count: {{ props?.row?.team_count }}</b-badge
              >
            </div>
          </template>

           <!-- Task & Subtask Count -->
           <template
                v-if="props?.column?.field === 'format_task_subtask_count'"
              >
                <div class="mt-1 mb-1">
                  <b>Task: </b>
                  <b-badge pill variant="light-primary">{{
                    formatType(props?.row?.tasks_count)
                  }}</b-badge>
                </div>

                <div class="mt-1">
                  <b>SubTask: </b>

                  <b-badge pill variant="light-primary">{{
                    formatType(props?.row?.sub_tasks_count)
                  }}</b-badge>
                </div>
              </template>

          <!-- supervisor info -->
          <template v-if="props?.column?.field == 'format_supervisor'">
            <span v-if="props?.row?.supervisor">
              <b-avatar
                :src="props?.row?.supervisor?.data?.avatar"
                class="mx-1"
              />
              <div class="text-nowrap table-cell-margin">
                {{ props?.row?.supervisor?.data?.name }} <br />
                <b-link :href="'mailto:' + props?.row?.supervisor?.data?.email">
                  {{ props?.row?.supervisor?.data?.email }}
                </b-link>
                <br />
                <span>
                  {{ props?.row?.supervisor?.data?.employee_number }}
                </span>
              </div>
            </span>
            <span v-else>
              <div class="text-nowrap">
                <span> Not Supervisor Assigned </span>
              </div>
            </span>
          </template>

          <!-- Milestone Info -->
          <template v-if="props?.column?.field === 'format_milestone'">
            <div v-if="props?.row?.milestone?.data?.title" class="mt-1">
              <span
                >{{ props?.row?.milestone?.data?.title }}
                <b-badge pill variant="light-primary">{{
                  formatMilestoneType(props?.row?.milestone?.data?.is_extra)
                }}</b-badge></span
              >
            </div>

            <div
              v-if="props?.row?.milestone?.data?.payment_type"
              class="mt-1 mb-1"
            >
              <b>Payment Type: </b>
              <b-badge pill variant="light-primary">{{
                formatType(props?.row?.milestone?.data?.payment_type)
              }}</b-badge>
            </div>
          </template>

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <template v-if="$permissionAbility(DIVISION_EDIT, permissions)">
                  <b-dropdown-item v-on:click="restore(props?.row?.id)">
                    <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                    <span>Restore</span>
                  </b-dropdown-item>
                </template>

                <template
                  v-if="$permissionAbility(DIVISION_DELETE, permissions)"
                >
                  <b-dropdown-item v-on:click="onDelete(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span> Delete Permanently</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormCheckboxGroup,
  BFormCheckbox,
  BLink,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  DIVISION_CREATE,
  DIVISION_EDIT,
  DIVISION_DELETE,
} from "@/helpers/permissionsConstant";

export default {
  name: "DivisionTrashView",
  components: {
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormCheckboxGroup,
    BFormCheckbox,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      DIVISION_CREATE,
      DIVISION_EDIT,
      DIVISION_DELETE,
      // modelType: "",
      // divisionId: "",
      // name: "",
      // selectHeadId: "",
      // headIdOption: [],
      pageLength: 10,
      columns: [
        {
          label: "Title",
          field: "title",
          sortable: false,
        },
        {
          label: "Task & SubTask Count",
          field: "format_task_subtask_count",
          sortable: false,
        },
        {
          label: "Supervisor",
          field: "format_supervisor",
          sortable: false,
        },
        {
          label: "Members",
          field: "format_members",
          sortable: false,
        },
        {
          label: "Milestone",
          field: "format_milestone",
          sortable: false,
        },
        {
          label: "Created On",
          field: "created_at",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      selectedRows: [],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isRowChecked: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: "created_at", type: "desc" }],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  methods: {
    formatMilestoneType(value) {
      if (value) {
        return "Extra";
      }
      return "Primary";
    },
    formatType(value) {
      if (value) {
        return value;
      }
      return "N/A";
    },
    showModal() {
      this.$bvModal.show("modal-division-form");
    },
    hiddenModal() {
      this.modelType = "";
      this.$bvModal.hide("modal-division-form");
      this.resetModal();
    },
    resetModal() {
      this.name = "";
      this.selectHeadId = "";
    },
    selectionChanged(params) {
      if (params?.selectedRows.length == 0) {
        this.isRowChecked = false;
        this.selectedRows = [];
      } else {
        this.isRowChecked = true;
        this.selectedRows = params?.selectedRows;
      }
    },
    async restore(id) {
      try {
        await this.$api.post(
          "api/milestone-task-boards/restore",

          {
            id: id,
          }
        );

        this.loadItems();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Successfuly Restored",
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "This action is Irreversible! Are you sure to delete this permanently?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.post(
              "api/milestone-task-boards/force-delete",

              {
                id: id,
              }
            );

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "This record has been permanently deleted ",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async restoreSelected() {
      const boardIds = (this.selectedRows || []).map((item) => {
        return item?.id;
      });

      try {
        await this.$api.post(
          "api/milestone-task-boards/restore-multiple",

          {
            ids: boardIds,
          }
        );

        this.loadItems();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Selected records successfuly restored",
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async deleteSelected() {
      const boardIds = (this.selectedRows || []).map((item) => {
        return item?.id;
      });

      this.$swal({
        title: "Warning!",
        text: "This action is irreversible! Are you sure to delete these divisions permanently?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.post(
              "api/milestone-task-boards/force-delete-multiple",
              {
                ids: boardIds,
              }
            );

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Selected records permanently deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    async getTrashedBoards(params) {
      return await this.$api.get(
        "api/milestone-task-boards/trashed?include=milestone,supervisor,team,deletedBy",
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
          },
        }
      );
    },

    async loadItems() {
      try {
        const [trashedBoards] = await Promise.all([
          this.getTrashedBoards({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
        ]);

        const data = trashedBoards?.data?.data;
        const meta = trashedBoards?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.table-custom-style {
  font-size: 13px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
.table-cell-margin {
  margin: 8px 0px;
}
</style>
